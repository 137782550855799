import React from "react";
import "../styles/Footer.css";
import Button from "@mui/material/Button";
import { IconContext } from "react-icons";
import { FaFacebookF, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="footer-col-wrapper-1">
          <h2>LOCATION</h2>
          <iframe
            className="gmap-iframe"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11766.504780123885!2d-89.031999!3d42.499499!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8808a122f1fe714b%3A0x89f699d69f8db2f5!2sStateline%20Mental%20Health%20Services%20LLC!5e0!3m2!1sen!2sus!4v1700254712782!5m2!1sen!2sus"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
            title="Stateline MHS Google Maps"
          ></iframe>
        </div>
        <div className="footer-col-wrapper-2">
          <h2>WHAT OUR CLIENTS ARE SAYING</h2>
          <h1>
            <i>based on our self-reporting survey</i>
          </h1>
          <p className="stateline-footer-reviews">
            "All of the staff are amazing and friendly!"
            <br></br>
            <br></br>
            "My therapist has made such a huge impact in my life and has helped
            shaped me into the person I am today."
            <br></br>
            <br></br>
            ​"I am thankful that Stateline Mental Health Services was there when
            I needed some help in finding new ways of thinking about my life."
            <br></br>
          </p>
          <a
            href="https://www.google.com/search?q=stateline+mhs&sca_esv=583420096&authuser=1&sxsrf=AM9HkKn1Rh-W3PlBmPtPGuy81w3yjYyflw%3A1700255250558&ei=EtZXZcTaIZeV0PEP_c2QkAs&ved=0ahUKEwjEq_Kx-MuCAxWXCjQIHf0mBLIQ4dUDCBA&uact=5&oq=stateline+mhs&gs_lp=Egxnd3Mtd2l6LXNlcnAiDXN0YXRlbGluZSBtaHMyDhAuGIAEGMcBGK8BGI4FMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyHRAuGIAEGMcBGK8BGI4FGJcFGNwEGN4EGOAE2AEBSJoPUIwDWIsOcAF4AZABAJgBhgGgAfUKqgEEMy4xMLgBA8gBAPgBAcICChAAGEcY1gQYsAPCAgoQIxiABBiKBRgnwgIQEC4YgAQYigUYxwEYrwEYQ8ICFxAuGIAEGIoFGLEDGIMBGMcBGNEDGJECwgILEAAYgAQYigUYkQLCAhAQLhiABBiKBRjHARjRAxhDwgIOEC4YgAQYigUYsQMYgwHCAhEQLhiABBixAxiDARjHARjRA8ICCxAAGIAEGLEDGIMBwgIfEC4YgAQYigUYxwEYrwEYQxiXBRjcBBjeBBjgBNgBAcICChAAGIAEGIoFGEPCAgUQABiABMICDhAAGIAEGIoFGLEDGIMBwgIXEC4YgwEYxwEYsQMY0QMYgAQYigUYkQLCAhYQLhiABBiKBRixAxiDARjHARjRAxhDwgILEC4YgAQYsQMYgwHCAgsQLhiABBjHARivAcICCBAuGIAEGLEDwgIFEC4YgATCAgsQLhivARjHARiABOIDBBgAIEGIBgGQBgK6BgYIARABGBQ&sclient=gws-wiz-serp#"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              style={{ border: "0.1em solid white", marginTop: "10%" }}
              className="review-button transition ease-in-out delay-100 hover:scale-125 duration-500"
            >
              <p style={{ color: "white" }}>Review us on Google!</p>
            </Button>
          </a>
        </div>

        <div className="footer-col-wrapper-3">
          <h2>CONTACT US</h2>
          <div className="social-media-wrapper">
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <a
                href="https://www.facebook.com/statelinemhs"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF className="transition ease-in-out delay-100 hover:scale-125 duration-300" />
              </a>
              <div className="icon-seperator"></div>
              <a
                href="https://www.instagram.com/statelinemhs/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram className="transition ease-in-out delay-100 hover:scale-125 duration-300" />
              </a>
              <div className="icon-seperator"></div>
              <a href="mailto:reception@statelinemhs.com">
                <MdEmail className="transition ease-in-out delay-100 hover:scale-125 duration-300" />
              </a>
              <div className="icon-seperator"></div>
              <a href="tel:6083688087">
                <FaPhoneAlt className="transition ease-in-out delay-100 hover:scale-125 duration-300" />
              </a>
            </IconContext.Provider>
          </div>
          <div className="sm:mt-8">
            <p
              className="text-white text-lg"
              style={{
                fontFamily: "Georgia, 'Times New Roman', Times, serif;",
              }}
            >
              540 E. Grand Ave<br></br>
              Beloit, WI 53511<br></br>
              <br></br>
              Business Hours<br></br>
              M-F 8am - 5pm <br></br>
              <br></br>
              Therapy appointments available<br></br>
              M-Th 8am - 7pm<br></br>
              Friday 8am - 5pm
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
