import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import smhsBuilding from "../images/AYA_9025.jpg";
import Footer from "./Footer";

const MentalHealthEducation = () => {

  useEffect(() => {
    document.title = "Stateline MHS - Mental Health Education"
  }, []);

  return (
    <>
      <section
        className="text-white pb-12"
        style={{
          backgroundImage: "url(" + smhsBuilding + ")",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          minWidth: "100%",
          zIndex: "0",
          filter: "contrast(130%)",
        }}
      >
        <div className="w-full px-12 min-w-full inline-block bg-cover bg-center relative">
          <div className="w-full h-full absolute top-0 left-0 bg-black opacity-60"></div>
          <div className="m-0 max-w-full w-full inline-block h-full relative md:px-8 lg:px-12 py-12 items-center justify-items-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl py-6 font-semibold">
              MENTAL HEALTH EDUCATION
            </h1>
            <p className="text-xl md:text-2xl lg:text-3xl py-6 md:px-20 lg:px-32 spacing-y-6">
              <br></br>
              Click on a button below to learn about some mental health
              topics.
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-1 gap-12 items-center justify-items-center py-12">
              <div className="w-full md:w-1/3 h-auto px-6 py-12 bg-big-blue rounded-full drop-shadow-xl text-white transition ease-in-out delay-100 hover:scale-125 duration-300">
                <Link to="eating-disorders">
                  <span className="text-xl md:text-xl lg:text-3xl py-2">
                    EATING DISORDERS
                  </span>
                </Link>
              </div>
            </div>

            <div className="w-full h-auto">
              <h1 className="text-3xl lg:text-4xl font-medium text-white">
                Stay tuned for more coming soon!
              </h1>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MentalHealthEducation;
