import React, { useState, useEffect } from "react";
import "../styles/SchoolBasedMentalHealth.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import beloitTurnerSD from "../images/school-partners/beloit-turner-school-district.jpeg";
import clintonSD from "../images/school-partners/clinton-school-district.jpeg";
import edgertonSD from "../images/school-partners/edgerton-school-district.jpeg";
import evansvilleSD from "../images/school-partners/evansville-school-district.jpeg";
import parkviewSD from "../images/school-partners/parkview-school-district.jpeg";
import brodheadSD from "../images/school-partners/brodhead-school-district.png";
import DDSD from "../images/school-partners/delavan-darien-school-district.png";
import elkhornSD from "../images/school-partners/elkhorn-school-district.png";
import lincolnAcademy from "../images/school-partners/lincoln-academy-logo.jpeg";
import miltonSD from "../images/school-partners/milton-school-district.png";
import Footer from './Footer';

function SchoolBasedMentalHealth() {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    document.title = "Stateline MHS - School Based Mental Health"
  }, []);

  return (
    <>
      <div className="faq-container">
        <div className="faq-container-header-box">
          <h2>SCHOOL BASED MENTAL HEALTH</h2>
        </div>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              borderBottom: '0.1em solid black'
            }}
          >
            <Typography className='accordian-header'> 
              <strong>What is School Based Mental Health?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='accordion-text'>
              School-based mental health services are professional assessments,
              therapies, and skill trainings provided to individual students at
              the school, with the support and involvement of the student's
              parent(s)/guardian(s). 
              <br></br><br></br>
              Our licensed clinical therapists provide
              services to pre-kindergarten, elementary, middle and high school
              students and their families.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              borderBottom: '0.1em solid black'
            }}
          >
            <Typography className='accordian-header'>
              <strong>Why provide school-based mental health services?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='accordion-text'>
              1. Remove barriers for students to access needed services{" "}
              <br></br>
              2. Ability to meet the child in their natural setting/comfort zone{" "}
              <br></br>
              3. Reduces the amount missed school time due to traveling for
              services <br></br>
              4. Increase the ability to take a collaborative approach to
              coordinate services with school, staff, and family <br></br>
              5. Support student success and their emotional well-being
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              borderBottom: '0.1em solid black'
            }}
          >
            <Typography className='accordian-header'>
              <strong>
                When are services available for school-based mental health
                services?
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='accordion-text'>
              Services are provided during the school day, evenings upon request
              per therapist availability, and during school breaks i.e. spring
              break and holiday breaks.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              borderBottom: '0.1em solid black'
            }}
          >
            <Typography className='accordian-header'>
              <strong>
                How to arrange for school-based mental health services...
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='accordion-text'>
              A member of the pupil services team may suggest to a student's
              parent(s)/guardian(s) that they could benefit from mental health
              services. If the parent(s)/guardian(s) are interested in accessing
              services for their child, they are able to complete the referral
              process online using the link on the schools website, contacting
              the school counselor, or contacting us directly at <strong><u>(608)-368-8087.</u></strong>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "5%",
          }}
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              borderBottom: '0.1em solid black',
            }}
          >
            <Typography className='accordian-header'>
              <strong>What schools are we partnered with?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='accordion-text'>
              We are currently partnered with the following districts: Beloit
              Turner, Brodhead, Clinton, Delavan-Darien, Edgerton, Elkhorn,
              Evansville, Milton, Parkview, and The Lincoln Academy.
              <br></br>
              <br></br>
              See below!
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="sbmh-partners-container">
        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={beloitTurnerSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://sites.google.com/turnerschools.org/turner-pupil-services/mental-health?authuser=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Beloit-Turner</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={brodheadSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.brodhead.k12.wi.us/families/pupil-services-resources.cfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Brodhead</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={clintonSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.clinton.k12.wi.us/student-services/school-based-mental-health-services.cfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Clinton</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={DDSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.ddschools.org/page/sbmh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Delavan-Darien</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={edgertonSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.edgerton.k12.wi.us/page/school-based-mental-health-services"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Edgerton</p>
                </a>
              </Button>
            </div>
          </div>
        </div>
        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={elkhornSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.elkhorn.k12.wi.us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Elkhorn</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={evansvilleSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.ecsdnet.org/district/school-based-mental-health-services.cfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Evansville</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={miltonSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.milton.k12.wi.us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Milton</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={parkviewSD} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.parkview.k12.wi.us/departments/CommunityCounselingServices.cfm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Parkview</p>
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="sbmh-polaroid">
          <div className="sbmh-card">
            <img src={lincolnAcademy} alt="" />
            <div className="sbmh-card-name">
              <Button border="none" outline="none" style={{paddingTop: '10%', width: '100%'}}>
                <a
                  href="https://www.thelincolnacademybeloit.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="sbmh-polaroid-name">Lincoln Academy</p>
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='sbmh-filler-container'></div>

      <Footer />
    </>
  );
}

export default SchoolBasedMentalHealth;
