import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import video1 from "../videos/eating-disorder-1.mp4";
import video2 from "../videos/eating-disorder-2.mp4";

const EatingDisorders = () => {
  return (
    <>
      <section className="py-12 bg-big-blue">
        <div className="px-12 pb-6 w-full h-full relative inline-block items-center justify-items-center">
          <Link to="/mental-health-education">
            <span
              className="text-3xl absolute top-0 left-6 md:left-12"
              style={{ color: "white" }}
            >
              &#8592;
            </span>
          </Link>
          <h1 className="text-3xl md:text-4xl pb-12 text-white">
            Eating Disorder Awareness Week (General)
          </h1>
          <video
            className="w-full h-1/2 md:h-5/6 lg:h-full drop-shadow-lg rounded-2xl"
            controls
            preload="metadata"
          >
            <source src={video1} />
            Your browser does not support the HTML video tag. Please try a
            different browser such as Google Chrome.
          </video>
        </div>
      </section>
      <section className="pb-24 bg-white">
        <div className="px-12 pt-6 w-full h-full">
          <h1 className="text-3xl md:text-4xl py-12">
            Eating Disorder Awareness Week (for Therapists)
          </h1>
          <video className="w-full h-full drop-shadow-lg rounded-2xl" controls preload="metadata">
            <source src={video2} />
            Your browser does not support the HTML video tag. Please try a
            different browser such as Google Chrome.
          </video>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EatingDisorders;
