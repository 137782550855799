import React from "react";
import Footer from "./Footer";
import lpImage from "../images/stateline_building.jpg";
import aboutImage from "../images/lp-about-us.jpeg";
import entrepreneurMedallion from "../images/entrepreneur-of-the-year-medallion.png";

const Home = () => {
  return (
    <>
      <div
        className="lp-image-container"
        style={{
          height: "84.5svh",
          backgroundImage: "url(" + lpImage + ")",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          minWidth: "100%",
          zIndex: "0",
          filter: "contrast(125%) brightness(120%)",
          position: "relative",
        }}
      >
      </div>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="flex order-2 sm:order-1 md:order-1 lg:order-1 md:flex-1">
            <img
              src={aboutImage}
              alt=""
              width={1300}
              className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              class="lp-images"
            />
          </div>
          <div className="lg:w-2/5 md:w-1/3 space-y-12 text-gray-700 md:py-8 order-1 sm:order-2 md:order-2 lg:order-2">
            <h1
              className="text-gray-900 font-semibold text-2xl sm:text-3xl md:text-4xl"
              id="about-text-header"
            >
              Stateline Mental Health Services
            </h1>
            <p className="text-lg" id="about-text-p">
              The mission of Stateline Mental Health Services, SC is to provide
              quality care for all clients in an environment that promotes
              healing and growth.
              <br></br>
              <br></br>
              We are a private mental health clinic providing counseling and
              psychotherapy services for individuals of all ages and
              backgrounds, couples and families. Please note we do not prescribe
              medication.
              <br></br>
              <br></br>
              Feel free to explore our page and if you have further questions,
              please contact us!
            </p>
            <div className="grid gap-10">
              <a
                href="tel:6083688087"
                className="flex gap-x-6 items-start"
                id="phone-container"
              >
                <span className="p-3 md:p-3.5 rounded-md bg-gray-100 text-gray-800 flex w-max">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                </span>
                <div className="space-y-0.5 flex flex-col flex-1">
                  <p className="text-foreground">Call Us</p>
                  <p className="font-semibold text-gray-900 text-lg">
                    (608) 368-8087
                  </p>
                </div>
              </a>
              <a
                href="mailto:reception@statelinemhs.com"
                className="flex gap-x-6 items-start"
                id="email-container"
              >
                <span className="p-3 md:p-3.5 rounded-md bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 flex w-max">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>
                </span>
                <div className="space-y-0.5 flex flex-col flex-1">
                  <p className="text-foreground">Email Us</p>
                  <p className="font-semibold text-gray-900 dark:text-white text-lg">
                    reception@statelinemhs.com
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="md:w-1/2 space-y-12 text-gray-700 dark:text-gray-300 md:py-8 rounded-2xl">
            <h1
              className="text-gray-900 dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl"
              id="history-text-header"
            >
              Our History
            </h1>
            <p className="text-lg leading-loose" id="history-text-p">
              Stateline Mental Health Services opened its doors to the residents
              of the Stateline area in November 2014 in hopes of offering
              additional mental health care to the area.
              <br></br>
              <br></br>
              Since its opening, Stateline Mental Health Services has grown
              exponentially. In 2018, we started our school based mental health
              program with one school district and now are in 10 districts
              around Rock County.
              <br></br>
              <br></br>
              We are honored to serve the people of the Stateline area and are
              grateful by the welcoming we have received from the community. We
              look forward to continuing to grow and provide care for the
              Stateline area.
            </p>
          </div>
          <div className="flex md:flex-1 order-2 rounded-xl justify-center items-center bg-big-blue">
            <img
              src={entrepreneurMedallion}
              alt=""
              width={1300}
              className="w-full h-auto md:h-full object-fit rounded-lg drop-shadow-lg"
              class="lp-images"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Home;
