import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import OurTherapists from './components/OurTherapists';
import SchoolBasedMentalHealth from './components/SchoolBasedMentalHealth';
import Resources from './components/Resources';
import AboutUs from './components/AboutUs';
import SupportStaff from './components/SupportStaff';
import Contact from './components/Contact';
import SMHSInTheCommunity from './components/SMHSInTheCommunity';
import Navbar from './components/Navbar';
import Services from './components/Services';
import MentalHealthEducation from './components/MentalHealthEducation';
import EatingDisorders from './components/EatingDisorders';
import SampleHome from './components/SampleHome';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/our-therapists' element={<OurTherapists />} />
          <Route path='/school-based-mental-health' element={<SchoolBasedMentalHealth/>} />
          <Route path='/resources' element={<Resources/>} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/support-staff' element={<SupportStaff/>} />
          <Route path='/contact-us' element={<Contact/>} />
          <Route path='/smhs-in-the-community' element={<SMHSInTheCommunity/>} />
          <Route path='/mental-health-education' element={<MentalHealthEducation/>} />
          <Route path='/mental-health-education/eating-disorders' element={<EatingDisorders/>} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
