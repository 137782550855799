import React, { useEffect } from "react";
import statelineLogo from "../images/stateline_home_logo.jpeg";
import noSurpriseAct from "../images/resource-logos/cms-gov.svg";
import namiRockCounty from "../images/resource-logos/nami-rock-county.png";
import nsplLogo from "../images/resource-logos/nspl.png";
import ctlLogo from "../images/resource-logos/Crisis_Text_Line_Logo.jpg";
import ttpLogo from "../images/resource-logos/ttp-logo.jpeg";
import projectMaria from "../images/resource-logos/project-maria.jpeg";
import project1649 from "../images/resource-logos/project-1649.png";
import Footer from "./Footer";

const Resources = () => {


  useEffect(() => {
    document.title = "Stateline MHS - Resources"
  }, []);

  return (
    <>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="flex md:flex-1 hidden md:block">
            <a
              href="https://smhsintouch.insynchcs.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={statelineLogo}
                alt=""
                width={1300}
                className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              />
            </a>
          </div>
          <div className="w-full lg:w-3/5 md:w-2/5 space-y-12 px-8 text-white dark:text-gray-300 md:py-8 flex flex-col align-center justify-center">
            <h1 className="text-white dark:text-white font-semibold text-4xl">
              CLIENT PORTAL
            </h1>
            <p className="text-xl sm:text-lg md:text-lg lg:text-xl">
              Click{" "}
              <a
                href="https://smhsintouch.insynchcs.com/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              to access the client portal!
              <br></br>
              <br></br>
              <a
                href="https://smhsintouch.insynchcs.com/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://smhsintouch.insynchcs.com/</u>
              </a>{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="md:w-1/2 space-y-12 text-gray-700 dark:text-gray-300 md:py-8 sm:order-2 md:order-1 rounded-2xl  flex flex-col align-center justify-center">
            <h1 className="text-gray-900 dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              NO SURPRISES ACT
            </h1>
            <p className="text-lg leading-loose">
              The No Surprises Act focuses on protecting people from unexpected,
              or surprise, medical bills.
              <br></br>
              <br></br>
              Click{" "}
              <a
                href="https://www.cms.gov/nosurprises"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://www.cms.gov/nosurprises"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://www.cms.gov/nosurprises</u>
              </a>
            </p>
          </div>
          <div className="hidden md:block flex md:flex-1 sm:order-1 md:order-2 rounded-xl justify-center items-center">
            <a
              href="https://www.cms.gov/nosurprises"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={noSurpriseAct}
                alt=""
                width={1300}
                className="w-full h-auto md:h-full object-fit rounded-lg drop-shadow-lg"
              />
            </a>
          </div>
        </div>
      </section>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="flex md:flex-1 hidden md:block">
            <a
              href="https://namirockcounty.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={namiRockCounty}
                alt=""
                width={1300}
                className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              />
            </a>
          </div>
          <div className="w-full lg:w-3/5 md:w-2/5 space-y-12 px-8 text-white dark:text-gray-300 md:py-8 flex flex-col align-center justify-center">
            <h1 className="text-white dark:text-white font-semibold text-4xl">
              NAMI ROCK COUNTY
            </h1>
            <p className="text-xl sm:text-lg md:text-lg lg:text-xl">
                NAMI Rock County offers support groups and education about mental illness.
                <br></br><br></br>
              Click{" "}
              <a
                href="https://namirockcounty.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://namirockcounty.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://namirockcounty.org/</u>
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="md:w-1/2 space-y-12 text-gray-700 dark:text-gray-300 md:py-8 sm:order-2 md:order-1 rounded-2xl  flex flex-col align-center justify-center">
            <h1 className="text-gray-900 dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              NATIONAL SUICIDE PREVENTION LIFELINE
            </h1>
            <p className="text-lg leading-loose">
              Call                 <a href='tel:+18002738255'>
                    <u>+1 (800) 273-8255</u>
                </a> <br></br>
                or
              <br></br>
              Click{" "}
              <a
                href="https://suicidepreventionlifeline.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://suicidepreventionlifeline.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://suicidepreventionlifeline.org/</u>
              </a>
            </p>
          </div>
          <div className="hidden md:block flex md:flex-1 sm:order-1 md:order-2 rounded-xl justify-center items-center">
            <a
              href="https://suicidepreventionlifeline.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={nsplLogo}
                alt=""
                width={1300}
                className="w-full h-auto object-fit rounded-lg drop-shadow-lg"
              />
            </a>
          </div>
        </div>
      </section>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="flex md:flex-1 hidden md:block">
            <a
              href="https://​www.crisistextline.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ctlLogo}
                alt=""
                width={1300}
                className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              />
            </a>
          </div>
          <div className="w-full lg:w-3/5 md:w-2/5 space-y-12 px-8 text-white dark:text-gray-300 md:py-8 flex flex-col align-center justify-center">
            <h1 className="text-white dark:text-white font-semibold text-4xl">
              CRISIS TEXT LINE
            </h1>
            <p className="text-xl sm:text-lg md:text-lg lg:text-xl">
            Text <strong>HOME</strong> to 741741
                <br></br><br></br>
              Click{" "}
              <a
                href="https://​www.crisistextline.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://​www.crisistextline.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://​www.crisistextline.org/</u>
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="md:w-1/2 space-y-12 text-gray-700 dark:text-gray-300 md:py-8 sm:order-2 md:order-1 rounded-2xl  flex flex-col align-center justify-center">
            <h1 className="text-gray-900 dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              THE TREVOR PROJECT
            </h1>
            <p className="text-lg leading-loose">
              The Trevor Project offers resources and support for LGBTQ youth.
              <br></br>
              <br></br>
              Click{" "}
              <a
                href="https://www.thetrevorproject.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://www.thetrevorproject.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://www.thetrevorproject.org/</u>
              </a>
            </p>
          </div>
          <div className="hidden md:block flex md:flex-1 sm:order-1 md:order-2 rounded-xl justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-500">
            <a
              href="https://www.thetrevorproject.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ttpLogo}
                alt=""
                width={1300}
                className="w-full h-auto object-fit rounded-lg drop-shadow-lg"
              />
            </a>
          </div>
        </div>
      </section>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="flex md:flex-1 hidden md:block">
            <a
              href="https://projectmaria.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={projectMaria}
                alt=""
                width={1300}
                className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              />
            </a>
          </div>
          <div className="w-full lg:w-3/5 md:w-2/5 space-y-12 px-8 text-white dark:text-gray-300 md:py-8 flex flex-col align-center justify-center">
            <h1 className="text-white dark:text-white font-semibold text-4xl">
              PROJECT MARIA
            </h1>
            <p className="text-xl sm:text-lg md:text-lg lg:text-xl">
              Project Maria focuses on offering education and support for eating disorders
              <br></br><br></br>
              Click{" "}
              <a
                href="https://projectmaria.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://projectmaria.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://projectmaria.org/</u>
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12 items-center justify-items-center">
          <div className="md:w-1/2 space-y-12 text-gray-700 dark:text-gray-300 md:py-8 sm:order-2 md:order-1 rounded-2xl  flex flex-col align-center justify-center">
            <h1 className="text-gray-900 dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              PROJECT 16:49
            </h1>
            <p className="text-lg leading-loose">
              Project 16:49 is a local non-profit organization serving Rock County’s unaccompanied homeless teens.
              <br></br>
              <br></br>
              Click{" "}
              <a
                href="https://project1649.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>here</u>
              </a>{" "}
              or the link below to learn more!
              <br></br>
              <br></br>
              <a
                href="https://project1649.org/"
                target="_blank"
                rel="noreferrer"
              >
                <u>https://project1649.org/</u>
              </a>
            </p>
          </div>
          <div className="hidden md:block flex md:flex-1 sm:order-1 md:order-2 rounded-xl justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-500">
            <a
              href="https://project1649.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={project1649}
                alt=""
                width={1300}
                className="w-full h-auto object-fit rounded-lg drop-shadow-lg"
              />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Resources;
