import React, { useState } from "react";
import logo from "../images/stateline-logo-transparent.png";

const Navbar = () => {
  const [navIsOpened, setNavIsOpened] = useState(false);
  const closeNavbar = () => {
    setNavIsOpened(false);
  };

  const toggleNavbar = () => {
    setNavIsOpened((navIsOpened) => !navIsOpened);
  };
  return (
    <>
      <div
        aria-hidden={true}
        onClick={() => {
          closeNavbar();
        }}
        className={`fixed bg-gray-800/40 inset-0 z-30 ${
          navIsOpened ? "" : "hidden lg:hidden"
        }`}
      />
      <header className="sticky left-0 top-0 w-full flex items-center h-20 border-b border-b-gray-100 dark:border-b-gray-900 z-40 bg-white dark:bg-gray-950 bg-opacity-80 backdrop-filter backdrop-blur-xl">
        <nav className="relative mx-auto lg:max-w-10xl w-full px-6 sm:px-6 md:px-8 lg:px-5 flex gap-x-5 justify-between items-center">
          <div className="flex items-center min-w-max">
            <a
              href="/"
              className="text-xl font-semibold flex items-center gap-x-2"
            >
              <span className="flex">
                <img src={logo} alt="" className="w-8 h-8" />
              </span>
              <span className="text-sm md:text-base lg:text-lg text-gray-700 dark:text-gray-300 hidden sm:block">
                Stateline Mental Health Services
              </span>
            </a>
          </div>
          <div
            className={`
          absolute top-14 left-0 bg-white dark:bg-gray-950 border-b border-gray-200 dark:border-gray-800 py-8 px-5 sm:px-10 md:px-12 w-full duration-300 ease-linear
          ${
            navIsOpened
              ? "translate-y-0 opacity-100 visible"
              : "translate-y-10 opacity-0 invisible lg:visible  lg:translate-y-0 lg:opacity-100 lg:bg-transparent lg:py-0 lg:px-0 lg:border-none lg:top-0 lg:relative lg:flex lg:justify-between"
          }
        `}
          >
            <ul
              className={`flex flex-col lg:flex-row gap-6 lg:items-center text-gray-700 dark:text-gray-300 lg:w-full lg:justify-end
                ${
                  navIsOpened
                    ? "lg:flex-col justify-center items-center"
                    : "lg:flex-row"
                }
            
            `}
            >
              <li>
                <a
                  href="/"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="/our-therapists"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Our Therapists
                </a>
              </li>
              <li
                className={`
                ${navIsOpened ? "visible" : "hidden"}
              `}
              >
                <a
                  href="/support-staff"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Support Staff
                </a>
              </li>
              <li>
                <a
                  href="/school-based-mental-health"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  School Based Mental Health
                </a>
              </li>
              <li
                className={`
                ${navIsOpened ? "visible" : "hidden"}
              `}
              >
                <a
                  href="/smhs-in-the-community"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  SMHS In The Community
                </a>
              </li>{" "}
              <li
                className={`
                ${navIsOpened ? "visible" : "hidden"}
              `}
              >
                <a
                  href="/mental-health-education"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Mental Health Education
                </a>
              </li>
              <li
                className={`
                ${navIsOpened ? "visible" : "hidden"}
              `}
              >
                <a
                  href="/resources"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Resources
                </a>
              </li>
              <li
                className={`
                ${navIsOpened ? "visible" : "hidden"}
              `}
              >
                <a
                  href="/about-us"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="relative py-2.5 duration-300 ease-linear hover:text-blue-600 after:absolute after:w-full after:left-0 after:bottom-0 after:h-px after:rounded-md after:origin-left after:ease-linear after:duration-300 after:scale-x-0 hover:after:scale-100 after:bg-blue-600"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="https://smhsintouch.insynchcs.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="px-6 items-center h-12 rounded-3xl text-blue-700 border border-gray-100 dark:border-gray-800 dark:text-white bg-gray-100 dark:bg-gray-900 duration-300 ease-linear flex justify-center max-w-max hover:scale-105 hover:bg-big-blue hover:text-white hover:border-0"
                >
                  Client Portal
                </a>
              </li>
            </ul>
          </div>
          <div aria-hidden="false" className="flex items-center lg:visible">
            <button
              onClick={() => {
                toggleNavbar();
              }}
              aria-label="toggle navbar"
              className="outline-none border-l border-l-indigo-100 dark:border-l-gray-800 pl-3 relative py-3"
            >
              <span
                aria-hidden={true}
                className={`
              flex h-0.5 w-6 rounded bg-gray-800 dark:bg-gray-300 transition duration-300
              ${navIsOpened ? "rotate-45 translate-y-[.324rem]" : ""}
            `}
              />
              <span
                aria-hidden={true}
                className={`
              mt-2 flex h-0.5 w-6 rounded bg-gray-800 dark:bg-gray-300 transition duration-300
              ${navIsOpened ? "-rotate-45 -translate-y-[.324rem]" : ""}
              `}
              />
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Navbar;
