import React, { useEffect } from "react";
import eligibilityImage from "../images/about-us-2.jpeg";
import appointmentImage from "../images/smhs-christmas-party.jpg";
import confidentialImage from "../images/IMG_5602.JPG";
import emergencyImage from "../images/about-us-4.jpeg";
import consentImage from '../images/smhs-group.jpeg';
import feeImage from '../images/smhs-booth.jpeg';
import accessImage from '../images/smhs-event.jpeg';
import Footer from './Footer';

const AboutUs = () => {

  useEffect(() => {
    document.title = "Stateline MHS - About Us"
  }, []);

  return (
    <>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="lg:w-2/5 sm:w-full md:py-8 flex flex-col space-y-12 align-center justify-center">
            <h1 className="text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              ELIGIBILITY
            </h1>
            <p className="text-white sm:text-md md:text-lg">
              Eligibility for Stateline Mental Health Services, SC counseling
              programs is based on the existence of a presenting problem. You
              may be referred to another community resource if you (1) do not
              meet the eligibility criteria; (2) there is not enough staff time
              available to help you; or (3) there is a more appropriate service
              provider elsewhere in the community or your insurance company has
              another counseling resource for you.
              <br></br>
              <br></br>
              After you begin working with Stateline Mental Health Services, SC,
              services may continue: (1) so long as there are identified
              treatment goals which have not yet been met and (2) there is
              evidence that you are interested in pursuing these goals.
              <br></br>
              <br></br>
              The agency may discontinue services if: (1) all treatment goals
              have been met; (2) you fail to demonstrate an interest in actively
              pursuing treatment goals, for example, by showing a pattern of
              regularly missing appointments; (3) you fail to pay for services
              as agreed upon in your Client Financial Agreement; or (4) upon the
              professional recommendation of your therapist.
            </p>
          </div>
          <div className="flex md:flex-1">
            <img
              alt=""
              src={eligibilityImage}
              width={1300}
              className="w-full md:h-full object-cover rounded-2xl drop-shadow-xl hidden lg:block"
            />
          </div>
        </div>
      </section>
      <section style={{ height: "45rem" }}>
        <div
          style={{ backgroundImage: "url(" + appointmentImage + ")" }}
          className="min-w-full h-full inline-block w-full bg-cover bg-center relative"
        >
          <div className="w-full h-full absolute top-0 left-0 bg-black opacity-75"></div>
          <div className="m-0 max-w-full w-full inline-block h-full relative flex flex-row px-12">
            <div className="sm:w-0 lg:w-1/2 sm:hidden lg:inline-block sm:py-0 lg:py-12 align-center justify-center">
              <img
                alt=""
                src={appointmentImage}
                className="object-cover sm:border-0 lg:border-2 sm:border-transparent lg:border-white sm:min-w-0 lg:min-w-full sm:h-0 lg:h-full hidden lg:block"
              />
            </div>
            <div className="relative sm:w-full lg:w-1/2 flex flex-col space-y-12 px-12 align-center justify-center">
              <h1 className="text-white font-bold text-3xl sm:text-3xl md:text-4xl">
                APPOINTMENTS
              </h1>
              <p className="text-white font-semibold text-xl sm:text-md md:text-xl">
                Appointments are scheduled with individual therapists. A
                counseling or psychotherapy hour consists of a 45-55 minute
                session with your therapist. If you need to cancel an
                appointment, please do so at least 24 hours in advance. You,{" "}
                <u>not</u> your insurance, will be billed for missed
                appointments.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-f-gray">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="lg:w-2/5 sm:w-full md:py-8 flex flex-col space-y-12 align-center justify-center">
            <h1 className="text-black font-semibold text-2xl sm:text-3xl md:text-4xl">
              CONFIDENTIALITY
            </h1>
            <p className="text-black sm:text-md md:text-lg">
              All contacts between staff and clients are strictly confidential
              and will not be revealed to any person or agency outside of
              Stateline Mental Health Serves, SC, without your written consent.
              <br></br>
              <br></br>
              The primary exceptions to this rule are those situations in which
              reporting is mandatory under Wisconsin law (e.g., child abuse,
              child neglect, sexual abuse, etc.).
              <br></br>
              <br></br>
              In addition, please note that your signature on the Client
              Financial Agreement gives the agency permission to release
              information necessary for the processing of claims for payment.
            </p>
          </div>
          <div className="flex md:flex-1">
            <img
              alt=""
              src={confidentialImage}
              width={1300}
              className="w-full md:h-full object-cover rounded-2xl drop-shadow-xl hidden lg:block"
            />
          </div>
        </div>
      </section>
      <section style={{ height: "45rem" }}>
        <div
          style={{ backgroundImage: "url(" + emergencyImage + ")" }}
          className="min-w-full h-full inline-block w-full bg-cover bg-center relative"
        >
          <div className="w-full h-full absolute top-0 left-0 bg-black opacity-75"></div>
          <div className="m-0 max-w-full w-full inline-block h-full relative flex flex-row px-12">
            <div className="sm:w-0 lg:w-1/2 sm:hidden lg:inline-block sm:py-0 lg:py-12 align-center justify-center">
              <img
                alt=""
                src={emergencyImage}
                className="object-cover sm:border-0 lg:border-2 sm:border-transparent lg:border-white sm:min-w-0 lg:min-w-full sm:h-0 lg:h-full hidden lg:block"
              />
            </div>
            <div className="relative sm:w-full lg:w-1/2 flex flex-col space-y-12 px-12 align-center justify-center">
              <h1 className="text-white font-bold text-3xl sm:text-3xl md:text-4xl">
                EMERGENCIES
              </h1>
              <p className="text-white font-semibold text-xl sm:text-md md:text-xl">
                In an emergency, you may call the office 24 hours, 7 days a week
                (608-368-8087) to speak to your/a therapist. During non-working
                hours, phone lines will be forwarded to a therapist’s cell phone
                to address non-life-threatening emergencies. Our goal is to
                return your call within the hour. If a situation is a life or
                death emergency, please contact 911.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-big-blue">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="lg:w-2/5 sm:w-full md:py-8 flex flex-col space-y-12 align-center justify-center">
            <h1 className="text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              CONSENT
            </h1>
            <p className="text-white sm:text-md md:text-lg">
            It is the policy of Stateline Mental Health Services, SC that each
            client, or individual acting on behalf of the client, will receive
            specific, complete and accurate information regarding the
            psychotherapy or other treatment they receive through the agency.
            <br></br><br></br>
            You will be asked to read and sign the Informed Consent Policy prior
            to beginning work with your therapist.
            </p>
          </div>
          <div className="flex md:flex-1">
            <img
              alt=""
              src={consentImage}
              width={1300}
              className="w-full object-cover rounded-2xl drop-shadow-xl hidden lg:block"
            />
          </div>
        </div>
      </section>
      <section style={{ height: "45rem" }}>
        <div
          style={{ backgroundImage: "url(" + accessImage + ")" }}
          className="min-w-full h-full inline-block w-full bg-cover bg-center relative"
        >
          <div className="w-full h-full absolute top-0 left-0 bg-black opacity-75"></div>
          <div className="m-0 max-w-full w-full inline-block h-full relative flex flex-row px-12">
            <div className="sm:w-0 lg:w-1/2 sm:hidden lg:inline-block sm:py-0 lg:py-12 align-center justify-center">
              <img
                alt=""
                src={accessImage}
                className="object-cover sm:border-0 lg:border-2 sm:border-transparent lg:border-white sm:min-w-0 lg:min-w-full sm:h-0 lg:h-full hidden lg:block"
              />
            </div>
            <div className="relative sm:w-full lg:w-1/2 flex flex-col space-y-12 px-12 align-center justify-center">
              <h1 className="text-white font-bold text-3xl sm:text-3xl md:text-4xl">
                CLIENT ACCESS TO RECORDS
              </h1>
              <p className="text-white font-semibold text-xl sm:text-md md:text-xl">
              Under Wisconsin law, you have a right to review your treatment
              record. Ask your therapist for the procedures used in sharing your
              file with you. If you feel your records contains incorrect
              information, ask your therapist for the procedure used to request
              a change in record information.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-f-gray">
        <div className="max-w-full mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="lg:w-2/5 sm:w-full md:py-8 flex flex-col space-y-12 align-center justify-center">
            <h1 className="text-black font-semibold text-2xl sm:text-3xl md:text-4xl">
              FEE POLICY
            </h1>
            <p className="text-black sm:text-md md:text-lg">
            A fee is charged for professional services provided by the
            therapists at Stateline Mental Health Services, SC. If you have
            private insurance or medical assistance, we will bill for services
            at the established rate. You are also responsible for full payments
            at the agreed upon rate once your maximum insurance benefits have
            been used. If you do not have insurance, or if your insurance does
            not pay in full, you will be responsible for paying the rate
            established on your Client Financial Agreement.
            <br></br>
            <br></br>
            If you are receiving services under managed care, health insurance,
            medical assistance or an EAP, the agency will need to obtain
            information about covered services, co-payments, deductibles, etc.
            The agency will either obtain the specific information required or
            ask you to obtain the information.
            </p>
          </div>
          <div className="flex md:flex-1">
            <img
              alt=""
              src={feeImage}
              width={1300}
              className="w-full object-cover rounded-2xl drop-shadow-xl hidden lg:block"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
