import React, { useEffect } from "react";
import Footer from "./Footer";

const Contact = () => {
  useEffect(() => {
    document.title = "Stateline MHS - Contact Us";
  }, []);

  return (
    <>
      <section className="">
        <div className="max-w-full bg-big-blue py-12 mx-auto px-3 sm:px-10 md:px-12 lg:px-16 flex flex-col md:flex-row gap-12">
          <div className="flex md:flex-1 order-2 md:order-1">
            <iframe
              width={1000}
              className="w-full md:h-full object-cover rounded-lg drop-shadow-xl"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11766.504780123885!2d-89.031999!3d42.499499!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8808a122f1fe714b%3A0x89f699d69f8db2f5!2sStateline%20Mental%20Health%20Services%20LLC!5e0!3m2!1sen!2sus!4v1700254712782!5m2!1sen!2sus"
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
              title="Stateline MHS Google Maps"
            ></iframe>
          </div>
          <div className="lg:w-3/5 md:w-3/5 space-y-12 text-white dark:text-gray-300 md:py-8 sm:order-1 md:order-2">
            <h1 className="text-white dark:text-white font-semibold text-2xl sm:text-3xl md:text-4xl">
              Stateline Mental Health Services
            </h1>
            <p className="text-lg">
              Stateline Mental Health Services is located at
              <br></br>
              540 E. Grand Ave<br></br>
              Beloit, WI 53511<br></br>
              <br></br>
              <div className="flex flex-row gap-12 mb-12">
                <p className="w-1/2">
                  Office Staff Business Hours<br></br>
                  M-F 8am - 5pm <br></br>
                </p>
                <p className="w-1/2">
                  Therapy appointments available<br></br>
                  M-Th 8am - 7pm<br></br>
                  Friday 8am - 5pm<br></br>
                </p>
                <br></br>
              </div>
              <i style={{ fontSize: "0.8em" }}>
                *Saturday appointments are considered on a case-by-case basis
                and scheduled only at a therapist's discretion.
              </i>
              <br></br>
              <br></br>
              <strong style={{ fontSize: "1.2em" }}>CLOSED SUNDAYS</strong>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-40 px-12 sm:px-12 md:px-16 lg:px-44 bg-white-200 text-black items-center justify-center">
          <a href="tel:6083688087" className="flex items-center">
            <span className="p-3 md:p-3.5 rounded-md bg-gray-200 text-gray-800 flex w-min">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </span>
            <div className="space-y-0.5 flex flex-col flex-1">
              <p className="text-foreground">Call Us</p>
              <p className="font-semibold dark:text-white text-lg">
                (608) 368-8087
              </p>
            </div>
          </a>
          <div className="flex gap-x-6 items-start">
            <span className="p-3 md:p-3.5 rounded-md bg-gray-200 text-gray-800 flex w-min">
              <svg
                className="w-6 h-6"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M756.03 739.942a26.081 26.081 0 0 1-26.056-26.05 26.086 26.086 0 0 1 26.055-26.061h61.686a13.066 13.066 0 0 0 13.046-13.05V352.72a13.066 13.066 0 0 0-13.046-13.055H206.3a13.097 13.097 0 0 0-13.05 13.046V674.78a13.071 13.071 0 0 0 13.05 13.051h61.624a26.086 26.086 0 0 1 26.056 26.06 26.081 26.081 0 0 1-26.056 26.051H206.3c-35.927 0-65.157-29.23-65.157-65.162V352.722c0-35.932 29.23-65.162 65.157-65.162h611.415c35.927 0 65.157 29.23 65.157 65.162V674.78c0 35.932-29.23 65.162-65.157 65.162H756.03z"
                  fill="#2C2C2C"
                />
                <path
                  d="M328.259 879.892c-35.927 0-65.158-29.23-65.158-65.162V572.006a26.081 26.081 0 0 1 26.051-26.05 26.076 26.076 0 0 1 26.05 26.05V814.73a13.071 13.071 0 0 0 13.051 13.051h368.37a13.071 13.071 0 0 0 13.05-13.05V570.188a26.086 26.086 0 0 1 26.06-26.056 26.081 26.081 0 0 1 26.051 26.056V814.73c0 35.932-29.23 65.162-65.162 65.162H328.26z m-39.537-540.226a26.081 26.081 0 0 1-26.05-26.05V208.04c0-35.927 29.224-65.157 65.156-65.157h370.555c35.933 0 65.163 29.225 65.163 65.157v105.574a26.117 26.117 0 0 1-26.071 26.05 26.086 26.086 0 0 1-26.056-26.045V208.041a13.066 13.066 0 0 0-13.046-13.05h-370.55a13.071 13.071 0 0 0-13.05 13.05v105.574a26.076 26.076 0 0 1-26.051 26.05z"
                  fill="#2C2C2C"
                />
                <path
                  d="M386.135 737.178a23.322 23.322 0 0 1-23.29-23.291 23.322 23.322 0 0 1 23.29-23.307h251.74a23.322 23.322 0 0 1 23.296 23.307 23.316 23.316 0 0 1-23.296 23.29h-251.74zM254.531 595.026c-14.367 0-26.051-11.689-26.051-26.056s11.689-26.05 26.05-26.05h514.955a25.85 25.85 0 0 1 18.247 7.864 25.861 25.861 0 0 1 7.394 18.519 25.907 25.907 0 0 1-25.687 25.718H254.53z m475.704-126.142a33.546 33.546 0 0 1-33.505-33.505c0-18.473 15.032-33.5 33.505-33.5s33.505 15.027 33.505 33.5a33.541 33.541 0 0 1-33.505 33.505z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            <div className="space-y-0.5 flex flex-col flex-1">
              <p className="text-foreground">Fax</p>
              <p className="font-semibold dark:text-white text-lg">
                (608) 312-2061
              </p>
            </div>
          </div>
          <a
            href="mailto:reception@statelinemhs.com"
            className="flex gap-x-6 items-start"
          >
            <span className="p-3 md:p-3.5 rounded-md bg-gray-200 text-gray-800 flex w-min">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                />
              </svg>
            </span>
            <div className="space-y-0.5 flex flex-col flex-1">
              <p className="text-foreground">Email Us</p>
              <p className="font-semibold dark:text-white text-lg">
                reception@statelinemhs.com
              </p>
            </div>
          </a>
          <a
            href="https://www.facebook.com/statelinemhs"
            className="flex gap-x-6 items-start"
          >
            <span className="p-3 md:p-3.5 rounded-md bg-gray-200 text-gray-800 flex w-min">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                className="w-6 h-6"
              >
                <path d="M 25 3 C 12.861562 3 3 12.861562 3 25 C 3 36.019135 11.127533 45.138355 21.712891 46.728516 L 22.861328 46.902344 L 22.861328 29.566406 L 17.664062 29.566406 L 17.664062 26.046875 L 22.861328 26.046875 L 22.861328 21.373047 C 22.861328 18.494965 23.551973 16.599417 24.695312 15.410156 C 25.838652 14.220896 27.528004 13.621094 29.878906 13.621094 C 31.758714 13.621094 32.490022 13.734993 33.185547 13.820312 L 33.185547 16.701172 L 30.738281 16.701172 C 29.349697 16.701172 28.210449 17.475903 27.619141 18.507812 C 27.027832 19.539724 26.84375 20.771816 26.84375 22.027344 L 26.84375 26.044922 L 32.966797 26.044922 L 32.421875 29.564453 L 26.84375 29.564453 L 26.84375 46.929688 L 27.978516 46.775391 C 38.71434 45.319366 47 36.126845 47 25 C 47 12.861562 37.138438 3 25 3 z M 25 5 C 36.057562 5 45 13.942438 45 25 C 45 34.729791 38.035799 42.731796 28.84375 44.533203 L 28.84375 31.564453 L 34.136719 31.564453 L 35.298828 24.044922 L 28.84375 24.044922 L 28.84375 22.027344 C 28.84375 20.989871 29.033574 20.060293 29.353516 19.501953 C 29.673457 18.943614 29.981865 18.701172 30.738281 18.701172 L 35.185547 18.701172 L 35.185547 12.009766 L 34.318359 11.892578 C 33.718567 11.811418 32.349197 11.621094 29.878906 11.621094 C 27.175808 11.621094 24.855567 12.357448 23.253906 14.023438 C 21.652246 15.689426 20.861328 18.170128 20.861328 21.373047 L 20.861328 24.046875 L 15.664062 24.046875 L 15.664062 31.566406 L 20.861328 31.566406 L 20.861328 44.470703 C 11.816995 42.554813 5 34.624447 5 25 C 5 13.942438 13.942438 5 25 5 z"></path>
              </svg>
            </span>
            <div className="space-y-0.5 flex flex-col flex-1">
              <p className="text-foreground">Facebook</p>
              <p className="font-semibold dark:text-white text-lg">
                facebook.com/StatelineMHS
              </p>
            </div>
          </a>
          <a
            href="https://www.instagram.com/statelinemhs"
            className="flex gap-x-6 items-start"
          >
            <span className="p-3 md:p-3.5 rounded-md bg-gray-200 text-gray-800 flex w-min">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                className="w-6 h-6"
              >
                <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
              </svg>
            </span>
            <div className="space-y-0.5 flex flex-col flex-1">
              <p className="text-foreground">Instagram</p>
              <p className="font-semibold dark:text-white text-lg">
                instagram.com/StatelineMHS
              </p>
            </div>
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
