import React, { useEffect } from "react";
import "../styles/SMHSInTheCommunity.css";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import slide1 from "../images/smhs-building-2.jpeg";
import slide2 from "../images/smhs-entrepreneur-of-the-year_orig.jpg";
import slide3 from "../images/smhs-partner-w-beloit-hospice_orig.jpg";
import slide4 from "../images/smhs-mainstreet-award_orig.jpg";
import slide5 from "../images/smhs-partner-w-nami_orig.jpg";
import slide6 from "../images/smhs-podcast.jpeg";
import slide7 from "../images/beloit-eggstravaganza.jpg";

function SMHSInTheCommunity() {

  useEffect(() => {
    document.title = "Stateline MHS - In The Community"
  }, []);

  return (
    <>
      <div className="yt-video-container">
        <div className="yt-video-embed-box">
          <iframe
            src="https://www.youtube.com/embed/P857-PYc2V8?si=g9ewaqLpAN6n8GqM"
            title="Stateline MHS on Good Day Stateline"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            style={{
              width: "95%",
              minHeight: "90%",
              border: "0",
            }}
          ></iframe>
        </div>
        <div className="yt-video-about-text-box">
          <div className="yt-video-about-text-area">
            <p>
              We appeared on Good Day Stateline to discuss mental health and
              school-based services!
              <br></br>
              <br></br>
              <br></br>
              Check us out!
            </p>
          </div>
        </div>
      </div>

      <div className="smhs-community-slideshow-container">
        <div className="slideshow-container">
          <Carousel
            autoPlay
            infiniteLoop
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${
                    hasPrev ? "absolute" : "hidden"
                  } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <FaArrowCircleLeft className="w-9 h-9 text-black" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`${
                    hasNext ? "absolute" : "hidden"
                  } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <FaArrowCircleRight className="w-9 h-9 text-black" />
                </div>
              );
            }}
          >
            <div className="slide">
              <img src={slide1} alt="" />
            </div>
            <div className="slide">
              <img src={slide6} alt="" />
              <iframe
                src="https://podcasters.spotify.com/pod/show/beloit-rising-profe/embed/episodes/Season-3-Ep-3-Stephanie-Knueppel-and-Kelley-Everson-e2a84n0/a-aaeo4i6"
                height="10%"
                width="100%"
                frameborder="0"
                scrolling="no"
                title="Stateline MHS Podcast"
              ></iframe>
            </div>
            <div className="slide">
              <img src={slide7} alt="" />
              <p className="legend">
                We participated in the Beloit Eggstravaganza. Read more <span className="font-semibold"><u><a href="https://www.beloitdailynews.com/news/local-news/beloit-south-beloit-egg-hunts-offer-prizes-family-fun/article_53b07ac2-eead-11ee-95e0-8faf7b3e2fe2.html" target="_blank" rel="noreferrer">HERE</a></u></span>!
              </p>
            </div>
            <div className="slide">
              <img src={slide2} alt="" />
              <p className="legend">
                Stephanie was nominated and received an award for Entrepreneur
                of the Year at the Beloit Chamber dinner!
              </p>
            </div>
            <div className="slide">
              <img src={slide3} alt="" />
            </div>
            <div className="slide">
              <img src={slide4} alt="" />
              <p className="legend">
                Stephanie accepted the award for Best Business Success Story at
                the Mainstreet Awards Ceremony in LaCrosse, WI.
              </p>
            </div>
            <div className="slide">
              <img src={slide5} alt="" />
            </div>
          </Carousel>
        </div>
      </div>

      <div className="smhs-community-filler-container"></div>

      <Footer />
    </>
  );
}

export default SMHSInTheCommunity;
